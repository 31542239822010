<template>
  <modal
    name="new-custom-campaign-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="400"
    height="auto"
    @closed="resetCampaignName"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <p class="modal__title text-align-center">
          {{ $t('dashboard.label.createNewMarketingCampaign') }}
        </p>
        <div class="flex-column modal__input-container">
          <div class="modal__input-label">{{ $t('dashboard.label.marketingCampaignTitle') }}</div>
          <input
            v-model="campaignName"
            type="text"
            class="modal__input rounded-input input-focus-within"
            :class="{ 'error-highlight': newCampaignTitleError || newCampaignTitleEmptyError }"
            :maxlength="100"
            @input="onCampaignNameInput"
          />
          <field-error
            v-if="newCampaignTitleError"
            :error-message="$t('dashboard.warning.titleIsAlreadyUsed')"
          />
        </div>
        <div class="flex--center--center">
          <a class="modal__cancel-button" @click="cancel">
            {{ $t('dashboard.action.cancel') }}
          </a>
          <button
            class="modal__create-button rounded-button-white"
            :disabled="isCreateButtonDisabled"
            @click="create"
          >
            {{ $t('dashboard.action.create') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isEmpty, get } from 'lodash';

import FieldError from '@/modules/dashboard/components/common/FieldError';

import { isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';
import { TEMPLATE_GROUP_TYPES } from '@/modules/dashboard/api/constants';

export default {
  name: 'NewCustomCampaignModal',
  components: {
    FieldError
  },
  data() {
    return {
      campaignName: '',
      newCampaignTitleError: false,
      newCampaignTitleEmptyError: false
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      hasNewCampaignTitleError: types.getters.HAS_NEW_CAMPAIGN_TITLE_ERROR,
      activeMarketingCampaign: types.getters.ACTIVE_MARKETING_CAMPAIGN
    }),
    isCreateButtonDisabled() {
      return !this.campaignName;
    }
  },
  methods: {
    ...mapActions({
      createCustomCampaign: types.actions.CREATE_CUSTOM_CAMPAIGN
    }),
    onCampaignNameInput() {
      if (this.newCampaignTitleEmptyError) {
        this.newCampaignTitleEmptyError = false;

        return;
      }

      if (!this.newCampaignTitleError) {
        return;
      }

      this.newCampaignTitleError = false;
    },
    hideModal() {
      this.resetCampaignName();

      this.$modal.hide('new-custom-campaign-modal');
    },
    resetCampaignName() {
      this.campaignName = '';
      this.newCampaignTitleError = false;
      this.newCampaignTitleEmptyError = false;
    },
    cancel() {
      this.hideModal();
    },
    async create() {
      if (isEmpty(this.campaignName)) {
        this.newCampaignTitleEmptyError = true;

        return;
      }

      await this.createCustomCampaign({
        userId: this.userId,
        customCampaign: {
          doctorId: this.userId,
          title: this.campaignName,
          type: TEMPLATE_GROUP_TYPES.CUSTOM_CAMPAIGN
        }
      });

      if (this.hasNewCampaignTitleError) {
        this.newCampaignTitleError = true;

        return;
      }

      const marketingCampaignId = get(this.activeMarketingCampaign, 'id');

      if (isMobileDevice() && marketingCampaignId) {
        this.$router.push({ name: 'MessageCampaign' });
      }

      this.hideModal();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/inputs';
@import '../../../../assets/css/common/errors';
@import '../../../../assets/css/common/icons';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.modal {
  &-window-content {
    padding: 30px 20px 20px;
  }

  &__title {
    margin-bottom: 25px;

    font-size: 15px;
    color: $grey-color;
  }

  &__select-input /deep/ .scrollable-dropdown {
    width: 100%;
  }

  &__input {
    height: 36px;
    width: 100%;

    margin-bottom: 20px;

    font-size: 15px;
    color: $text-color;

    &-label {
      margin-bottom: 10px;

      font-size: 13px;
      color: $text-color;
    }
  }

  &__cancel-button {
    margin-right: 20px;
  }
}

.select-placeholder {
  font-size: 15px;
  color: $icon-grey-color;
}

@media (max-width: 767px) {
  .modal {
    &-container {
      margin: 0 auto;
      width: 300px;
    }

    &-container,
    &__title {
      font-size: 13px;
    }

    &__input,
    &__cancel-button,
    &__create-button {
      font-size: 16px;
      line-height: 19px;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__input {
      margin-bottom: 26px;

      &:last-child {
        margin-bottom: 24px;
      }
    }

    &__input-label {
      font-size: 12px;
    }
  }

  .select__list-element {
    font-size: 13px;
  }
}
</style>
