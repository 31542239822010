import { render, staticRenderFns } from "./CustomCampaignsTab.vue?vue&type=template&id=7dd56592&scoped=true&"
import script from "./CustomCampaignsTab.vue?vue&type=script&lang=js&"
export * from "./CustomCampaignsTab.vue?vue&type=script&lang=js&"
import style0 from "./CustomCampaignsTab.vue?vue&type=style&index=0&id=7dd56592&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dd56592",
  null
  
)

export default component.exports