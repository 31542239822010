<template>
  <template-with-header>
    <div class="templates__container">
      <div class="templates__breadcrumbs">
        <span class="breadcrumbs__link">
          <router-link :to="{ name: 'Dashboard' }" :title="$t('dashboard.action.home')">
            <span @click="resetOpenedPatient">{{ $t('dashboard.action.home') | upperCase }}</span>
          </router-link>
        </span>
        /
        <span class="breadcrumbs__current no-search-field">
          {{ $t('dashboard.label.messageTemplates') }}
        </span>
      </div>

      <tabs :tabs="tabs" :active-tab="activeTab" @select-tab="selectTab">
        <div slot="tab-content">
          <component :is="activeTab"></component>
        </div>
      </tabs>
    </div>

    <unsaved-changes-modal />
  </template-with-header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TemplateWithHeader from '@/modules/dashboard/components/templates/TemplateWithHeader';
import Tabs from '@/modules/dashboard/components/common/Tabs';
import SkinxsCampaignsTab from '@/modules/dashboard/components/message-templates/SkinxsCampaignsTab';
import CustomCampaignsTab from '@/modules/dashboard/components/message-templates/CustomCampaignsTab';
import NotificationsTab from '@/modules/dashboard/components/message-templates/NotificationsTab';
import UnsavedChangesModal from '@/modules/dashboard/components/message-templates/UnsavedChangesModal';

import templateUnsavedChangesMixin from '@/modules/dashboard/components/mixins/templateUnsavedChangesMixin';
import saveMarketingTemplateMixin from '@/modules/dashboard/components/mixins/saveTemplateMixin';

import TabInfo from '@/modules/dashboard/components/common/TabInfo';

import { isMobileDevice } from '@/modules/dashboard/utils';

import { types } from '@/modules/dashboard/store/types';
import { MESSAGE_TEMPLATES_TAB_NAMES } from '@/modules/dashboard/api/constants';

export default {
  name: 'MessageTemplates',
  components: {
    TemplateWithHeader,
    Tabs,
    SkinxsCampaignsTab,
    CustomCampaignsTab,
    NotificationsTab,
    UnsavedChangesModal
  },
  mixins: [templateUnsavedChangesMixin, saveMarketingTemplateMixin],
  data() {
    return {
      tabs: [
        new TabInfo(
          MESSAGE_TEMPLATES_TAB_NAMES.SKINXS_CAMPAIGNS,
          this.$t('dashboard.label.marketingCampaigns'),
          'SkinxsCampaigns'
        ),
        new TabInfo(
          MESSAGE_TEMPLATES_TAB_NAMES.CUSTOM_CAMPAIGNS,
          this.$t('dashboard.label.customCampaigns'),
          'CustomCampaigns'
        ),
        new TabInfo(
          MESSAGE_TEMPLATES_TAB_NAMES.NOTIFICATIONS,
          this.$t('dashboard.label.notifications'),
          'Notifications'
        )
      ],
      activeTab: null
    };
  },
  computed: {
    ...mapGetters({
      isMessageTemplateUpdated: types.getters.IS_MESSAGE_TEMPLATE_UPDATED,
      activeMessageTemplateTab: types.getters.ACTIVE_MESSAGE_TEMPLATE_TAB
    })
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isMessageTemplateUpdated) {
      next();

      return;
    }

    const canContinue = await this.onTemplateClose();

    if (!canContinue) {
      next(false);

      return;
    }

    next();
  },
  created() {
    this.activeTab = this.activeMessageTemplateTab || MESSAGE_TEMPLATES_TAB_NAMES.SKINXS_CAMPAIGNS;
  },
  async beforeDestroy() {
    await this.setActiveMessageTemplateTab(null);
  },
  methods: {
    ...mapActions({
      setUnsavedChangesPromiseResolver: types.actions.SET_UNSAVED_CHANGES_PROMISE_RESOLVER,
      setActiveMessageTemplateTab: types.actions.SET_ACTIVE_MESSAGE_TEMPLATE_TAB,
      setActiveMarketingCampaign: types.actions.SET_ACTIVE_MARKETING_CAMPAIGN,
      resetOpenedPatient: types.actions.RESET_OPENED_PATIENT
    }),
    async selectTab(tabName) {
      const canContinue = await this.onTemplateClose();

      if (!canContinue) {
        return;
      }

      if (!isMobileDevice()) {
        await this.setActiveMarketingCampaign(null);
      }

      this.activeTab = tabName;
    }
  },
  metaInfo: {
    title: 'Message Templates',
    titleTemplate: 'skinXs - %s'
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/helpers/colors';

.templates {
  &__container {
    /deep/ .tabs {
      width: 100%;

      justify-content: flex-start;
    }
  }

  &__breadcrumbs {
    margin: 30px 0;

    font-size: 15px;
    font-weight: bold;
    color: $grey-color;
  }

  &__tabs {
    display: flex;
  }
}

.breadcrumbs {
  &__current {
    font-size: 18px;
    color: $main-title-color;
  }
}

@media (max-width: 767px) {
  .templates {
    &__container {
      /deep/ .tabs {
        &__container {
          margin: 14px 0 15px;

          .tab {
            flex-basis: 0;
            font-weight: 600;

            &:first-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }

    &__breadcrumbs {
      margin: 15px 0 15px 15px;

      font-size: 12px;
    }
  }

  .breadcrumbs {
    &__current {
      font-size: 14px;
    }
  }
}
</style>
