<template>
  <div class="custom-campaigns__page-container">
    <div v-if="hasCampaigns">
      <div
        class="custom-campaigns__new-button rounded-button-white"
        @click="openNewCustomCampaignModal"
      >
        {{ $t('dashboard.action.newMarketingCampaign') }}
      </div>
      <div class="custom-campaigns__list-container">
        <div class="campaign__list">
          <div
            v-for="campaign in campaigns"
            :key="campaign.id"
            class="campaign__list-element pointer"
            :class="{
              'campaign__list-element--active': campaign.id === activeCampaignId
            }"
            @click="pickCampaign(campaign)"
          >
            <div class="campaign__label-container mb15">
              <span class="campaign__title-container">
                <v-clamp autoresize :max-lines="3" tag="span" class="skinxs-campaigns__title">
                  {{ campaign.title }}
                </v-clamp>
                <span class="fas fa-chevron-right onlyXS campaign__arrow-icon"></span>
              </span>
            </div>
            <div v-if="campaign.id === activeCampaignId && !isMobile">
              <div
                v-for="type in $options.messageTemplateTypes"
                :key="type"
                class="type__container flex--align-center"
                @click.stop="pickType(type)"
              >
                <span
                  class="mr10 inline-block custom-icon otherXS"
                  :class="getTemplateIconClass(type)"
                ></span>
                {{ getTemplateTypeName(type) }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="activeCampaign.id && !isMobile" class="campaign__content otherXS">
          <marketing-campaign-template
            :key="`${activeCampaignId}${activeTemplateType}`"
            :message-template-type="activeTemplateType"
          />
        </div>
      </div>
    </div>

    <div v-else class="custom-campaigns__no-campaigns flex-column--align-center">
      <div class="icon-marketing-speaker custom-icon"></div>
      <span class="no-campaigns__text main-text">{{ $t('dashboard.label.noCustomCampaign') }}</span>
      <div
        class="no-campaigns__new-button rounded-button-white"
        @click="openNewCustomCampaignModal"
      >
        {{ $t('dashboard.action.createNewMarketingCampaign') }}
      </div>
    </div>

    <new-custom-campaign-modal />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VClamp from 'vue-clamp';
import { isEmpty, get, first } from 'lodash';

import NewCustomCampaignModal from '@/modules/dashboard/components/message-templates/NewCustomCampaignModal';
import MarketingCampaignTemplate from '@/modules/dashboard/components/message-templates/MarketingCampaignTemplate';

import templateUnsavedChangesMixin from '@/modules/dashboard/components/mixins/templateUnsavedChangesMixin';

import { isMobileDevice, getMessageTemplateTypeIconClass } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';

import {
  TEMPLATE_TYPE_NAME,
  MESSAGE_TEMPLATE_TYPES,
  MESSAGE_TEMPLATE_TYPE_VALUES
} from '@/modules/dashboard/api/constants';

export default {
  name: 'CustomCampaignsTab',
  components: {
    VClamp,
    NewCustomCampaignModal,
    MarketingCampaignTemplate
  },
  mixins: [templateUnsavedChangesMixin],
  messageTemplateTypes: MESSAGE_TEMPLATE_TYPE_VALUES,
  data() {
    return {
      isMobile: isMobileDevice(),
      activeTemplateType: MESSAGE_TEMPLATE_TYPES.EMAIL
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      campaigns: types.getters.MARKETING_CAMPAIGNS,
      activeCampaign: types.getters.ACTIVE_MARKETING_CAMPAIGN,
      isMessageTemplateUpdated: types.getters.IS_MESSAGE_TEMPLATE_UPDATED,
      savedMessageTemplate: types.getters.SAVED_MESSAGE_TEMPLATE
    }),
    activeCampaignId() {
      return get(this.activeCampaign, 'id');
    },
    hasCampaigns() {
      return !isEmpty(this.campaigns);
    }
  },
  async created() {
    this.setLoading(true);

    await this.loadCustomCampaigns(this.userId);

    if (!isEmpty(this.campaigns) && !this.isMobile) {
      await this.setActiveMarketingCampaign(first(this.campaigns));
    }

    this.setLoading(false);
  },
  async destroyed() {
    if (this.$route.name === 'MessageCampaign') {
      return;
    }

    await this.setActiveMarketingCampaign(null);
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      loadCustomCampaigns: types.actions.LOAD_CUSTOM_CAMPAIGNS,
      setActiveMarketingCampaign: types.actions.SET_ACTIVE_MARKETING_CAMPAIGN,
      updateStoreMessageTemplate: types.actions.UPDATE_STORE_MESSAGE_TEMPLATE
    }),
    async openNewCustomCampaignModal() {
      const canContinue = await this.onTemplateClose();

      if (!canContinue) {
        return;
      }

      if (!this.isMessageTemplateUpdated) {
        this.updateStoreMessageTemplate({ ...this.savedMessageTemplate });
      }

      this.$modal.show('new-custom-campaign-modal');
    },
    async pickCampaign(campaign) {
      const canContinue = await this.onTemplateClose();

      if (!canContinue) {
        return;
      }

      this.pickType(MESSAGE_TEMPLATE_TYPES.EMAIL);
      await this.setActiveMarketingCampaign(campaign);

      if (this.isMobile) {
        this.$router.push({ name: 'MessageCampaign' });
      }
    },
    async pickType(type) {
      const canContinue = await this.onTemplateClose();

      if (!canContinue) {
        return;
      }

      this.activeTemplateType = type;
    },
    getTemplateIconClass(type) {
      const isActive = this.activeTemplateType === type;

      return getMessageTemplateTypeIconClass(type, isActive);
    },
    getTemplateTypeName(type) {
      return TEMPLATE_TYPE_NAME[type];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/text';

.custom-campaigns {
  &__page-container {
    margin-bottom: 50px;
  }

  &__new-button {
    width: 230px;

    font-size: 15px;
  }

  &__list-container {
    display: flex;

    margin-top: 30px;
  }

  &__no-campaigns {
    margin-top: 140px;
  }
}

.campaign {
  &__list {
    width: 25%;

    &-element {
      min-height: 60px;

      padding: 20px;

      font-size: 15px;
      font-weight: bold;
      color: $text-color;

      &--active {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        background-color: $white;

        .skinxs-campaigns__title {
          color: $grey-color;
        }
      }
    }
  }

  &__label-container {
    padding-left: 25px;
  }

  &__type-icon {
    margin: 0 5px -2px 0;
  }

  &__content {
    width: 75%;

    border-radius: 8px;
    background-color: $white;
  }
}

.type {
  &__container {
    font-size: 13px;
    font-weight: normal;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.no-campaigns {
  &__text {
    margin-top: 40px;

    color: $grey-color;
  }

  &__new-button {
    width: 280px;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .custom-campaigns {
    &__page-container {
      margin-bottom: 30px;
      padding: 10px 0 0;
    }

    &__list-container {
      margin-top: 10px;
    }

    &__new-button {
      height: 32px;
      width: 200px;

      font-size: 13px;

      margin: 0 auto 15px;
    }
  }

  .campaign {
    &__list {
      width: 100%;

      &-element {
        min-height: 15px;
        display: flex;

        padding: 12px 20px;

        font-size: 12px;

        &--active {
          border-radius: 0;
        }
      }
    }

    &__label-container {
      width: 100%;
      padding-left: 0;
      margin-bottom: 0;
      position: relative;
    }

    &__type-icon {
      position: absolute;

      left: 0;
    }

    &__title-container {
      width: 100%;

      display: flex;
      justify-content: space-between;
    }

    &__arrow-icon {
      align-self: center;

      font-size: 9px;
      color: $dim-grey-color;
    }
  }
}
</style>
