<template>
  <div class="notifications__tab-container">
    <div class="notifications__list-container flex">
      <div class="notifications__list">
        <div
          v-for="notification in notifications"
          :key="notification.type"
          class="notifications__list-element notification-element pointer"
          :class="{
            'notifications__list-element--active': notification.type === activeNotification
          }"
          @click="pickNotification(notification.type)"
        >
          <div class="notification-element__name mb15">
            {{ $t(`dashboard.label.notification.${notification.name}`) }}
          </div>
          <div v-if="notification.type === activeNotification" class="otherXS">
            <div
              v-for="type in notification.templateTypes"
              :key="type.type"
              class="type__container flex--align-center"
              @click.stop="pickType(type.type)"
            >
              <span
                class="mr10 inline-block custom-icon"
                :class="getTemplateIconClass(type.type)"
              />
              {{ $t(`dashboard.notifications.${type.name}`) }}
            </div>
          </div>
          <span class="notification-element__icon fas fa-chevron-right onlyXS"></span>
        </div>
      </div>
      <div v-if="activeType" class="notifications__content otherXS">
        <notification-template
          :key="`${activeNotification}-${activeType}`"
          :notification-type="activeNotification"
          :template-type="activeType"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import templateUnsavedChangesMixin from '@/modules/dashboard/components/mixins/templateUnsavedChangesMixin';

import TypeObject from '@/modules/dashboard/components/common/TypeObject';
import NotificationTemplate from '@/modules/dashboard/components/message-templates/NotificationTemplate';

import {
  getMessageTemplateTypeIconClass,
  isMobileDevice,
  convertToLowerCase
} from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  NOTIFICATION_TEMPLATES_TYPES,
  NOTIFICATION_TEMPLATE_TITLE_KEYS_POSTFIX,
  MESSAGE_TEMPLATE_TYPES
} from '@/modules/dashboard/api/constants';

const TEMPLATE_TYPES_SMS_EMAIL = [
  new TypeObject('emailNotification', MESSAGE_TEMPLATE_TYPES.EMAIL),
  new TypeObject('smsNotification', MESSAGE_TEMPLATE_TYPES.SMS)
];

const TEMPLATE_TYPES_EMAIL = [new TypeObject('emailNotification', MESSAGE_TEMPLATE_TYPES.EMAIL)];

const NOTIFICATIONS = [
  new TypeObject(
    NOTIFICATION_TEMPLATE_TITLE_KEYS_POSTFIX[NOTIFICATION_TEMPLATES_TYPES.QUESTIONNAIRE_COMPLETED],
    NOTIFICATION_TEMPLATES_TYPES.QUESTIONNAIRE_COMPLETED,
    [...TEMPLATE_TYPES_SMS_EMAIL]
  ),
  new TypeObject(
    NOTIFICATION_TEMPLATE_TITLE_KEYS_POSTFIX[NOTIFICATION_TEMPLATES_TYPES.INCOMPLETE_QUESTIONNAIRE],
    NOTIFICATION_TEMPLATES_TYPES.INCOMPLETE_QUESTIONNAIRE,
    [...TEMPLATE_TYPES_EMAIL]
  ),
  new TypeObject(
    NOTIFICATION_TEMPLATE_TITLE_KEYS_POSTFIX[NOTIFICATION_TEMPLATES_TYPES.NEW_TREATMENT],
    NOTIFICATION_TEMPLATES_TYPES.NEW_TREATMENT,
    [...TEMPLATE_TYPES_EMAIL]
  )
];

const TREATMENT_COMPLETED_NOTIFICATION = [
  new TypeObject(
    NOTIFICATION_TEMPLATE_TITLE_KEYS_POSTFIX[NOTIFICATION_TEMPLATES_TYPES.TREATMENT_COMPLETED],
    NOTIFICATION_TEMPLATES_TYPES.TREATMENT_COMPLETED,
    [...TEMPLATE_TYPES_SMS_EMAIL]
  )
];

const TREATMENT_COMPLETED_MICRONEEDLING_NOTIFICATION = new TypeObject(
  NOTIFICATION_TEMPLATE_TITLE_KEYS_POSTFIX[
    NOTIFICATION_TEMPLATES_TYPES.TREATMENT_COMPLETED_MICRONEEDLING
  ],
  NOTIFICATION_TEMPLATES_TYPES.TREATMENT_COMPLETED_MICRONEEDLING,
  [...TEMPLATE_TYPES_SMS_EMAIL]
);

const WEBSHOP_FLOW_NOTIFICATIONS = [
  new TypeObject(
    NOTIFICATION_TEMPLATE_TITLE_KEYS_POSTFIX[NOTIFICATION_TEMPLATES_TYPES.TREATMENT_OFFER],
    NOTIFICATION_TEMPLATES_TYPES.TREATMENT_OFFER,
    [...TEMPLATE_TYPES_SMS_EMAIL]
  ),
  new TypeObject(
    NOTIFICATION_TEMPLATE_TITLE_KEYS_POSTFIX[NOTIFICATION_TEMPLATES_TYPES.REMINDER_OFFER],
    NOTIFICATION_TEMPLATES_TYPES.REMINDER_OFFER,
    [...TEMPLATE_TYPES_SMS_EMAIL]
  ),
  new TypeObject(
    NOTIFICATION_TEMPLATE_TITLE_KEYS_POSTFIX[NOTIFICATION_TEMPLATES_TYPES.RENEW_OFFER],
    NOTIFICATION_TEMPLATES_TYPES.RENEW_OFFER,
    [...TEMPLATE_TYPES_SMS_EMAIL]
  )
];

const RENEW_OFFER_MICRONEEDLING_NOTIFICATION = new TypeObject(
  NOTIFICATION_TEMPLATE_TITLE_KEYS_POSTFIX[NOTIFICATION_TEMPLATES_TYPES.RENEW_OFFER_MICRONEEDLING],
  NOTIFICATION_TEMPLATES_TYPES.RENEW_OFFER_MICRONEEDLING,
  [...TEMPLATE_TYPES_SMS_EMAIL]
);

export default {
  name: 'NotificationsTab',
  components: { NotificationTemplate },
  mixins: [templateUnsavedChangesMixin],
  data() {
    return {
      notifications: [...NOTIFICATIONS],
      activeNotification: '',
      activeType: ''
    };
  },
  computed: {
    ...mapGetters({
      hasWebshopAccess: rootTypes.getters.HAS_WEBSHOP_ACCESS,
      isMicroneedlingAvailable: dashTypes.getters.IS_MICRONEEDLING_AVAILABLE
    })
  },
  created() {
    this.notifications = this.hasWebshopAccess
      ? [...NOTIFICATIONS, ...WEBSHOP_FLOW_NOTIFICATIONS]
      : [...NOTIFICATIONS, ...TREATMENT_COMPLETED_NOTIFICATION];

    if (this.isMicroneedlingAvailable) {
      const microneedlingNotification = this.hasWebshopAccess
        ? RENEW_OFFER_MICRONEEDLING_NOTIFICATION
        : TREATMENT_COMPLETED_MICRONEEDLING_NOTIFICATION;

      this.notifications.push(microneedlingNotification);
    }

    if (isMobileDevice()) {
      return;
    }

    this.activeNotification = NOTIFICATION_TEMPLATES_TYPES.QUESTIONNAIRE_COMPLETED;
    this.activeType = MESSAGE_TEMPLATE_TYPES.EMAIL;
  },
  methods: {
    getTemplateIconClass(type) {
      const isActive = type === this.activeType;

      return getMessageTemplateTypeIconClass(type, isActive);
    },
    async pickNotification(notificationType) {
      const canContinue = await this.onTemplateClose();

      if (!canContinue) {
        return;
      }

      this.activeNotification = notificationType;

      if (isMobileDevice()) {
        this.$router.push({
          name: 'Notification',
          params: {
            notificationType: convertToLowerCase(this.activeNotification)
          }
        });

        return;
      }

      this.activeType = MESSAGE_TEMPLATE_TYPES.EMAIL;
    },
    async pickType(type) {
      const canContinue = await this.onTemplateClose();

      if (!canContinue) {
        return;
      }

      this.activeType = type;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.notifications {
  &__list {
    width: 25%;

    &-element {
      padding: 20px;

      font-size: 15px;
      font-weight: bold;
      color: $main-title-color;

      &--active {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;

        background-color: $white;
      }
    }
  }

  &__content {
    width: 75%;

    border-radius: 8px;
    background-color: $white;
  }
}

.type {
  &__container {
    font-size: 13px;
    font-weight: normal;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 767px) {
  .notifications {
    &__list {
      width: 100%;

      &-element {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 12px 20px;

        font-size: 12px;
      }
    }
  }

  .notification-element {
    &__name {
      margin-bottom: 0;
    }

    &__icon {
      font-size: 9px;
      color: $dim-grey-color;
    }
  }

  .type {
    &__container {
      font-size: 10px;
      font-weight: normal;
    }
  }
}
</style>
