<template>
  <div class="skinxs-campaigns__tab-container">
    <div class="skinxs-campaigns__list-container flex">
      <div class="skinxs-campaigns__list">
        <div
          v-for="campaign in campaigns"
          :key="campaign.id"
          class="skinxs-campaigns__list-element pointer"
          :class="{
            'skinxs-campaigns__list-element--active': campaign.id === activeCampaignId
          }"
          @click="pickCampaign(campaign)"
        >
          <div class="skinxs-campaign flex--align-center space-between mb15">
            <div class="flex">
              <div class="icon-button icon-skinxs"></div>
              <div class="skinxs-campaigns__title">
                {{ $t($options.titlesConfig[campaign.title]) }}
              </div>
            </div>
            <span class="skinxs-campaign__next-icon fas fa-chevron-right onlyXS"></span>
          </div>
          <div v-if="campaign.id === activeCampaignId && !isMobile">
            <div
              v-for="type in $options.messageTemplateTypes"
              :key="type"
              class="type__container flex--align-center"
              @click.stop="pickType(type)"
            >
              <span
                class="mr10 inline-block custom-icon otherXS"
                :class="getTemplateIconClass(type)"
              ></span>
              {{ templateTypes[type] }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeType && !isMobile" class="skinxs-campaigns__content otherXS">
        <marketing-campaign-template
          :key="`${activeCampaignId}${activeType}`"
          :message-template-type="activeType"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { first, isEmpty, get } from 'lodash';

import MarketingCampaignTemplate from '@/modules/dashboard/components/message-templates/MarketingCampaignTemplate';

import templateUnsavedChangesMixin from '@/modules/dashboard/components/mixins/templateUnsavedChangesMixin';

import { getMessageTemplateTypeIconClass, isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';

import {
  MESSAGE_TEMPLATE_TYPE_VALUES,
  MESSAGE_TEMPLATE_TYPES,
  TEMPLATE_TYPE_NAME,
  MARKETING_CAMPAIGN_TITLES_KEY
} from '@/modules/dashboard/api/constants';

export default {
  name: 'SkinxsCampaignsTab',
  components: { MarketingCampaignTemplate },
  mixins: [templateUnsavedChangesMixin],
  messageTemplateTypes: MESSAGE_TEMPLATE_TYPE_VALUES,
  titlesConfig: MARKETING_CAMPAIGN_TITLES_KEY,
  data() {
    return {
      templateTypes: TEMPLATE_TYPE_NAME,
      activeType: MESSAGE_TEMPLATE_TYPES.EMAIL,
      isMobile: isMobileDevice()
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      campaigns: types.getters.MARKETING_CAMPAIGNS,
      activeCampaign: types.getters.ACTIVE_MARKETING_CAMPAIGN
    }),
    activeCampaignId() {
      return get(this.activeCampaign, 'id');
    }
  },
  async created() {
    this.setLoading(true);
    await this.loadSkinxsCampaigns(this.userId);
    this.setLoading(false);

    if (isEmpty(this.campaigns)) {
      return;
    }

    if (this.isMobile) {
      return;
    }

    const firstCampaignId = get(first(this.campaigns), 'id');

    await this.setActiveSkinxsCampaignById(firstCampaignId);
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      loadSkinxsCampaigns: types.actions.LOAD_SKINXS_CAMPAIGNS,
      setActiveMarketingCampaign: types.actions.SET_ACTIVE_MARKETING_CAMPAIGN
    }),
    getTemplateIconClass(type) {
      const isActive = type === this.activeType;

      return getMessageTemplateTypeIconClass(type, isActive);
    },
    findCampaignById(campaignId) {
      return this.campaigns.find(({ id }) => id === campaignId);
    },
    async setActiveSkinxsCampaignById(campaignId) {
      const campaign = this.findCampaignById(campaignId);

      if (!campaign) {
        return;
      }

      await this.setActiveMarketingCampaign(campaign);
    },
    async pickCampaign({ id: campaignId }) {
      const canContinue = await this.onTemplateClose();

      if (!canContinue) {
        return;
      }

      this.activeType = MESSAGE_TEMPLATE_TYPES.EMAIL;

      if (this.isMobile) {
        await this.setActiveSkinxsCampaignById(campaignId);

        this.$router.push({ name: 'MessageCampaign' });

        return;
      }

      await this.setActiveSkinxsCampaignById(campaignId);
    },
    async pickType(type) {
      const canContinue = await this.onTemplateClose();

      if (!canContinue) {
        return;
      }

      this.activeType = type;

      if (this.isMobile) {
        this.$router.push({ name: 'MessageCampaign' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.skinxs-campaigns {
  &__list {
    width: 25%;

    &-element {
      padding: 20px 0 5px 20px;

      font-size: 15px;
      font-weight: bold;
      color: $main-title-color;

      &--active {
        padding: 20px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;

        background-color: $white;

        .skinxs-campaigns__title {
          color: $grey-color;
        }
      }
    }
  }

  &__content {
    width: 75%;

    border-radius: 8px;
    background-color: $white;
  }

  &__title {
    color: $text-color;
  }
}

.type {
  &__container {
    font-size: 13px;
    font-weight: normal;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.icon-skinxs {
  flex-shrink: 0;

  margin: 2px 8px 0 0;
}

@media (max-width: 767px) {
  .skinxs-campaigns {
    &__list {
      width: 100%;

      &-element {
        display: flex;
        flex-direction: column;

        padding: 12px 20px;

        font-size: 12px;
      }
    }
  }

  .skinxs-campaign {
    margin-bottom: 0;

    &__next-icon {
      font-size: 9px;

      color: $dim-grey-color;
    }
  }

  .type {
    &__container {
      font-size: 10px;
      font-weight: normal;
    }
  }

  .icon-skinxs {
    margin-top: 0;
  }
}
</style>
